
#addDataa1 {
   background-color: #0DA718;
   padding-top: 20px;
   padding-bottom: 20px;
   position: fixed;
   top: 58px;
   left: 0;
   right: 0;
   bottom: 0;
   overflow: auto;
   }
   .eraserIconColor {
      stroke-width: 1;
      stroke: rgb(0 0 0) !important;
      fill: none;
      vector-effect: non-scaling-stroke;
   }
.form1 {
      border: 2px solid #ECECEC;
      width: 80%;
      margin: auto;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      background-color : white;
}
.spysymboldata{
      height: 30px;
      border-radius: 4px;
      display: flex;
      margin-right: 1;
      
}
.spy{
      font-size: 14px;
      margin-left : 3px;
      margin-right :3px;
      margin-top: 5px;
}
   .Pcpchart {
      margin-top: 2px;
      margin-left: 2px; 
      display: flex;
   }

   .selectbox{
         display: flex;
         gap: 10px;
         margin-left: 3px;
         margin-top: 2px;
   }
.periodSelectorr{
      font-size: 14px;
      border-radius: 4px;
      height: 31px;
      border-color: #f2f2f2,
}
/* header */
@media only screen and (max-width: 320px) {
   .navbarheader {
      margin-left: 25px !important;
      margin-top: 2px !important;
      margin-bottom: 10px !important;
   }

}

@media only screen and (min-width: 320px)and (max-width: 375px) {
   .navbarheader {
      margin-left: 45px !important;
      margin-top: 1px !important;
      margin-bottom: 10px !important;
   }

   .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 10px 10px !important;
   }

   .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 10px 10px !important;
   }

   .pcp-indicator {
      padding-left: 0 !important;
   }

   .time-range {
      padding-left: 10px !important;
   }

   .datetime-picker,
   .time-picker {
      padding-left: 0px !important;
   }

   .heading-name {
      text-align: center;
      font-size: 17px !important;
   }
}

@media only screen and (min-width: 375px)and (max-width: 425px) {
   .navbarheader {
      margin-left: 65px !important;
      margin-top: 1px !important;
      margin-bottom: 10px !important;
   }

   .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 10px 10px !important;
   }

   .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 10px 10px !important;
   }

   .time-range {
      padding-left: 10px !important;
   }

   .datetime-picker,
   .time-picker {
      padding-left: 0px !important;
   }

}

@media only screen and (min-width: 425px)and (max-width: 600px) {
   .navbarheader {
      margin-left: 85px !important;
      margin-top: 1px !important;
      margin-bottom: 10px !important;
   }

   .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 10px 10px !important;
   }

   .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 10px 10px !important;
   }

   .time-range {
      padding-left: 10px !important;
   }

   .datetime-picker,
   .time-picker {
      padding-left: 0px !important;
   }

}

@media only screen and (min-width: 0px)and (max-width: 600px) {
   .datetime-picker {
      margin-left: 6px !important;
   }

   .time-picker {
      margin-left: 8px !important;
   }
}

@media only screen and (min-width: 600px)and (max-width: 900px) {
   .datetime-picker {
      margin-left: -10px !important;
   }

   .time-picker {
      margin-left: -7px !important;
   }
}


.Dashboard {
   float: left;
   margin-top: 20px !important;
}
@media only screen and (min-width: 0px)and (max-width: 350px) {
   .Dashboard {
      float: right !important;
      margin-top: 5px !important;
   }
}

.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
   background-color: green;
   color: white;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
   background-color: green !important;
   color: '#fff';
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
   background-color: green !important;
}

/* .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
   color: #fff;
   background-color: green !important;
   font-weight: 500;
 } */

.MuiPickersDay-dayWithMargin.Mui-selected {
   color: #fff;
   background-color: green !important;
   font-weight: 500;
}

.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-toolbar {
   grid-column: 2/4;
   grid-row: 1;
   background-color: green;
}

.css-1hbyad5-MuiTypography-root {
   color: #fff !important;
}

.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
   color: #fff !important;
}

.css-7kirvq-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
   color: #fff !important;
}

.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-actionBar {
   display: none !important;
}

.css-eg3pzz-MuiClockPointer-thumb {
   background-color: rgb(13, 167, 24) !important;
   border: 16px solid rgb(13, 167, 24) !important;
}

.css-umzx0k-MuiClock-pin {
   background-color: rgb(13, 167, 24) !important;
}

.css-d0vs79-MuiClockPointer-root {
   background-color: rgb(13, 167, 24) !important;
}

.pcp-indicator {
   padding-left: 0px !important;
}

.datetime-picker .css-11a8txn-MuiStack-root {
   width: 100%;
}

@media only screen and (min-width: 0px) {
   .css-1lugbff-MuiPickersLayout-root .MuiPickersLayout-toolbar {
      background-color: green;
   }

   .css-1lugbff-MuiPickersLayout-root .MuiPickersLayout-actionBar {
      display: none;
   }

   .css-1psulnz-MuiTypography-root-MuiDatePickerToolbar-title {
      color: #fff;
   }

}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
   position: unset !important;
}

@media only screen and (min-width: 0px)and (max-width: 320px) {
   .stockchart {
      margin-top: 96px !important;
   }

   .add-margin {
      margin-top: 170px !important;
   }
}

@media only screen and (min-width: 321px)and (max-width: 375px) {
   .stockchart {
      margin-top: 94px !important;
   }

   .add-margin {
      margin-top: 165px !important;
   }
}

@media only screen and (min-width: 375px)and (max-width: 402px) {
   .stockchart {
      margin-top: 94px !important;
   }

   .add-margin {
      margin-top: 130px !important;
   }
}

@media only screen and (min-width: 403px)and (max-width: 416px) {
   .stockchart {
      margin-top: 95px !important;
   }

   .add-margin {
      margin-top: 130px !important;
   }
}

@media only screen and (min-width: 417px)and (max-width: 425px) {
   .stockchart {
      margin-top: 60px !important;
   }

   .add-margin {
      margin-top: 130px !important;
   }
}

@media only screen and (min-width: 425px)and (max-width: 456px) {
   .stockchart {
      margin-top: 64px !important;
   }

   .add-margin {
      margin-top: 130px !important;
   }
}

@media only screen and (min-width: 457px)and (max-width: 519px) {
   .stockchart {
      margin-top: 64px !important;
   }

   .add-margin {
      margin-top: 130px !important;
   }
}
@media only screen and (min-width: 519px)and (max-width: 600px) {
   .stockchart {
      margin-top: 34px !important;
   }

   .add-margin {
      margin-top: 100px !important;
   }
}

@media only screen and (min-width: 601px)and (max-width: 685px) {
   .stockchart {
      margin-top: 34px !important;
   }

   .add-margin {
      margin-top: 65px !important;
   }
}

@media only screen and (min-width: 686px) and (max-width: 856px) {
   .stockchart {
      margin-top: 34px !important;
   }

   .add-margin {
      margin-top: 60px !important;
   }
}

@media only screen and (min-width: 857px) and (max-width: 1250px) {
   .stockchart {
      margin-top: 15px !important;
   }

   .add-margin {
      margin-top: 30px !important;
   }
}


@media only screen and (min-width: 1251px) and (max-width: 1465px){
   .stockchart {
      margin-top: 4px !important;
   }

   .add-margin {
      margin-top: 30px !important;
   }
}
@media only screen and (min-width: 1466px){
   .stockchart {
      margin-top: 4px !important;
   }

   .add-margin {
      /* margin-top: 25px !important; */
   }
}

/* 
@media only screen and (min-width: 0px)and (max-width: 320px) {
   .seemore {
      margin: -5px;
   }
   .add-space {
      margin-top: 0px !important;
   }
   .stockchart {
      margin-top: 70px !important;
   }
   .add-margin {
      margin-top: 190px !important;
   }
} */

@media only screen and (min-width: 601px) {
   .collapse {
      display: flex;
   }
}

/* .seemore {
   font-size: 11px !important;
   margin: -5px;
}
@media only screen and (min-width: 0px)and (max-width: 320px) {
   .seemore {
      font-size: 11px !important;
      margin: -5px;
      float: left;
   }
} */
@media only screen and (min-width: 0px)and (max-width: 320px) {
   .seemore {
      margin-top: 0px !important;
      font-size: 11px !important;
   }

   .add-space {
      margin-top: 140px !important;
   }
}

@media only screen and (min-width: 320px)and (max-width: 375px) {
   .seemore {
      margin-top: 0px !important;
      font-size: 11px !important;
   }

   .add-space {
      margin-top: 140px !important;
   }

   /* .add-margin {
      margin-top: 200px !important;
   } */
}

@media only screen and (min-width: 375px)and (max-width: 425px) {
   .seemore {
      margin-top: 0px !important;
      font-size: 11px !important;
   }

   .add-space {
      margin-top: 140px !important;
   }

   /* .add-margin {
      margin-top: 200px !important;
   } */
}

@media only screen and (min-width: 425px)and (max-width: 600px) {
   .seemore {
      margin-top: 0px !important;
      font-size: 11px !important;
   }

   .add-space {
      margin-top: 140px !important;
   }

   /* .add-margin {
      margin-top: 200px !important;
   } */
}
